.footer {
    box-shadow: 0px 1px 11px 3px rgba(95,95,95,0.3);
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;

    @include media-breakpoint-up('lg') {
        padding: 20px 0;
    }
}

.footer-logo {
    text-align: center;
    a {
        max-width: 120px;
        display: inline-block;
        @include mq($min-width: $media-tablet) {
            max-width: 140px;
        }
    }
}

.footer-nav {
    display: none;

    @include media-breakpoint-up('lg') {
        display: block;
    }
}

.footer-social {
    text-align: center;
    margin-bottom: 1.5rem;
    li {
        display: inline-block;
        vertical-align: top;
        padding: 0px 5px;
        a {
            display: block;
            text-indent: -9999px;

            &.twitter {
                @include generic-icon(24px, 21px, -33px, 0, 0, left, 0, 24px);
            }
            &.facebook {
                @include generic-icon(11px, 21px, 0, 0, 0, left, 0, 11px);
            }
            &.youtube {
                @include generic-icon(18px, 21px, -73px, 0, 0, left, 0, 18px);
            }
            &.instagram {
                @include generic-icon(21px, 21px, -108px, 0, 0, left, 0, 21px);
            }

        }
    }
}
.copy {
    font-family: $font-family;
    color: #000;
    text-align: center;
    margin-bottom: 1em;
    display: none;

    @include media-breakpoint-up('lg') {
        display: block;
    }
}

.footer-nav-list {
    text-align: center;
    li {
        display: inline-block;
        vertical-align: top;
        padding: 0 4px;
        @include mq($min-width: $media-tablet){
            padding: 0px 10px;
        }
        a {
            color: #000;
            font-size: 13px;
            font-weight: 300;
            text-decoration: none;
            font-family: 'Montserrat', sans-serif;
            &:hover {
                border-bottom: 1px dotted #000;
            }
        }
    }
}

.footer-scroll-top a {
    position: fixed;
    bottom: 15px;
    right: -40px;
    width: 35px;
    height: 35px;
    background-color: #fff;
    display: block;
    text-indent: -9999px;
    visibility: hidden;
    transition: right .4s linear;
    box-shadow: 0 0 2px rgba(0,0,0,0.4);
    &:after,&:before{
        content: '';
        position: absolute;
        width: 20px;
        height: 2px;
        display: block;
        background-color: #de0041;
        top: 45%;
        left: 0;
        transform-origin: top top;
    }
    &:after{
        transform: translate(0%,50%) rotate(-45deg);
    }
    &:before {
        transform: translate(50%,50%) rotate(-135deg);
        margin-left: 3px;
    }
}

.footer-scroll-top-on a {
    right: 15px;
    visibility: visible;
}

.cookie-advise {
    position: fixed;
    z-index: 99;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 95%;
    background-color: rgba(0,0,0,0.95);
    padding: 10px;
    p {
        margin: 0;
    }
}

.cookie-advise-text {
    font-family: $font-family;
    color: #fff;
    font-size: 11px;
    @include mq($min-width: $media-desktop) {
        font-size: 13px;
    }
    margin: 0;
    padding: 0;
    a {
        color: #fff;
        border-bottom: 1px dotted #fff;
    }
}

.cookie-advise-bt {
    background-color: #fff;
    color: #000;
    border: none;
    position: relative;
    margin-top: 8px;
    font-family: $font-family;
    font-weight: 600;
    @include mq($min-width: $media-desktop) {
        margin-top: 0px;
    }
}

.cookie-advise-close {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    opacity: 0.8;
    text-indent: -9999px;
    display: block;
    &:hover {
        opacity: 1;
    }
    &:before,
    &:after {
        position: absolute;
        left: 10px;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #fff;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}