@import "../node_modules/sass-mediaqueries/media-queries";

$media-tablet: 768px;
$media-desktop: 992px;
$media-desktop-xl: 1400px;
$step: 600px;
$media: $media-tablet $media-desktop $media-desktop-xl;
$font-family: "Roboto", sans-serif;
$headings-font-family: "Montserrat", sans-serif;
$headings-color: #009cdd;
$link-color: #009cdd;
$text-color: #666;
$golden-ratio: 1.61803398875;
$burguer-size: 35px;
$contwidth: calc(100% - 1140px);

@import "bootstrapcustom";
@import "reset";
@import "mixins";
@import "burguer";
@import "footer";


* {
	box-sizing: border-box;
}

html,body {
	font-family: $font-family;
	font-size: 1rem;

	overflow-x: hidden;

	margin: 0;
	padding: 0;
}

#body {
	@include media-breakpoint-up('lg') {
		position: relative;
		top: 0;
	}
}

a {
	color: $link-color;
	text-decoration: none;
}

em {
	font-style: italic;
}

.header {
	position: relative;
	background-color: #fafafa;
	padding: 10px 0;
	display: none;

	@include media-breakpoint-up('lg') {
		display: block;
	}
}

.header-language {
	position: absolute;
	top: 10px;
	right: 10px;

	@include mq($max-width: $media-tablet) {
		top: 5px;
    	right: 5px;
	}
}

.langswitcher {

	.lang-active {
		display: none;
	}

	li {
		&:after {
			display: none;
		}

		display: inline-block;
		margin: 0 -2px;

		@include mq($max-width: $media-tablet) {
			margin: 0 -5px;
		}

		a {
			font-size: 16px;
			font-family: 'Montserrat', sans-serif;
			font-weight: 400;
			text-decoration: none;
			color: #464646;

			@include mq($max-width: $media-tablet) {
				font-size: 12px;
			}
		}

		&:last-child {
			padding-left: 5px;
		}
	}
}

.header-container {
	@include mq($min-width: $media-desktop) {
		position: relative;

		display: flex;

		justify-content: space-between;
	}
}


.video-container {
	@include mq($min-width: $media-desktop) {
		margin-bottom: 60px;
	}

	position: relative;
	margin: 0 auto;
	margin-bottom: 30px;
	cursor: pointer;

	img {
		width: 100%;
		object-fit: cover;
		max-height: 500px;
	}

	.play-icon {
		background: rgba(255,255,255,0.4);
		width: 100px;
		height: 100px;
		border-radius: 50%;
		position: absolute;
        top: 50%;
        left: 50%;
		transform: translate(-50%,-50%);


		&:before {
			content: "";
			width: 0;
			height: 0;
			border-top: 25px solid transparent;
			border-left: 50px solid #fff;
			border-bottom: 25px solid transparent;
			z-index: 10;
			position: absolute;
			top: 52%;
			left: 55%;
			transform: translate(-50%, -50%);
		}
	}
}

.section-feat {

	p {
		@include font-size-media(1.2rem,1.3rem,1.5rem,1.5rem);

		font-weight: 700;

		color: $headings-color;
	}
}

.mes-header {
	background-position: center center;
	background-size: cover;
	box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
	height: 700px;
	max-height: 300px;

	@include mq($min-width: $media-tablet) {
		max-height: 700px;
	}

	h2 {
		font-size: 2rem;
		padding-top: 100px;
		color: #000;
		text-align: center;
		font-style: italic;

		@include mq($min-width: $media-tablet) {
			font-size: 2.5rem;
			padding-top: 180px;
		}

		@include mq($min-width: $media-desktop) {
			font-size: 3rem;
		}
	}

	.langswitcher {
		position: absolute;
		top: 20px;
		margin-left: 1rem !important;
		display: inline-block;
		right: 20px;

		a {
			color: #fff;
			padding: 10px;
			background: rgba(255,255,255,0.3);
			border-radius: 5px;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 14px;
			transition: all 0.1s ease-in;

			&:hover {
				background: rgba(255,255,255,0.5);
			}
		}
	}
}

.linea {
	height: 6px;
	background: url(../images/footer-header.svg),linear-gradient(45deg, #007dc4, #de0041);
	background-repeat: no-repeat ,no-repeat;
	background-position: center center, center center;
}


h1, h2, h3 {
	font-family: $headings-font-family;
	font-weight: 700;
}

.body-main {

	p {
		font-family: $font-family;
		font-size: 18px;
		font-weight: 300;
		line-height: 1.618em;
		margin-bottom: 1.2em;
	}

	strong {
		font-weight: 700;
	}

	h2.title {
		font-size: 28px;
		color: #000;
		padding-bottom: 20px;

		@include media-breakpoint-up('lg') {
			font-size: 32px;
			padding-bottom: 30px;
		}
	}
}

h3 {
	font-size: 22px;
	color: #000;
	padding-bottom: 20px;

	@include media-breakpoint-up('lg') {
		font-size: 26px;
		padding-bottom: 50px;
	}
}

.video-under-container {
	padding: 20px 15px 30px;

	.video-under-title {
		font-size: 1.8em;
		color: #000;
		padding: 10px 0 20px;
	}
}

.video-under {
	a {
		text-decoration: none;

		&:hover {
			border-bottom: 1px dotted $link-color;
		}
	}
}

.btn-exponer {
	text-align: center;
	margin: 50px auto 30px;
	background-color: #fbe7cf;
	padding: 15px 35px;
	max-width: 600px;

	a {
		text-transform: uppercase;
		font-family: 'Montserrat', sans-serif;
		font-weight: 700;
		font-size: 22px;
		line-height: 28px;
		color: #000;
		text-decoration: none;
		text-align: center;
	}

	&:hover {
		background-color: #515151;

		a {
			color: #fff;
		}
	}
}

.share-container,
.pagination-container,
.medioambiente-container,
.body-main {
	.container {
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		padding-top: 50px;
		position: relative;
		top: -70px;
		background-color: #fff;

		.col-md-10 {
			@include media-breakpoint-down('sm') {
				padding: 0 30px;
			}
		}
	}
}

.share-container,
.body-main {
	background-color: #fff;
}

.share {
	.article-share-items {
		vertical-align: super;
		margin: 20px 0 80px;

		li {
			display: inline-block;
			max-width: 24%;
    		width: 100%;

			@include media-breakpoint-up('lg') {
				max-width: initial;
				width: auto;
			}

			a {
				display: block;
				text-indent: -9999px;
				margin: auto;
				background-image: url(../images/fundacion-sprite.svg);
				background-repeat: no-repeat;
				position: relative;
				top: -5px;
				border: 1px solid #bcc0cc;
				border-radius: 50%;
				width: 40px;
				height: 40px;

				@include media-breakpoint-up('lg') {
					margin-right: 45px;
				}

				&:hover {
					background-color: #f1f1f1;
				}
			}

			.twitter {
				background-position: -25px 9px;
			}

			.facebook {
				background-position: 12px 8px;
			}

			.instagram {
				background-position: -99px 8px;
			}

			.email {
				background-position: -136px 8px;
			}
		}
	}
}

.pagination-container {
	background-color: #e8e8ec;
	padding-top: 70px;

	.container {
		background-color: #e8e8ec;
		top: -140px;
	}
}

.pagination {
	display: inline-block;
}

.previous-box {
	float: left;
	position: relative;
	left: 50px;

	@include media-breakpoint-down('xs') {
		left: 30px;
	}
}

.next-box {
	float: right;
	position: relative;
	right: 50px;

	@include media-breakpoint-down('xs') {
		right: 20px;
	}
}

.separator {
	width: 1px;
	background-color: #bcc0cc;
	height: 100%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.previous, .next {
	color: $link-color;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	font-size: 16px;
	text-decoration: none;
	margin-bottom: 20px;
	border-bottom: 1px dotted transparent;

	@include media-breakpoint-down('md') {
		max-width: 100px;
		text-align: center;
		display: block;
	}

	&:hover {
		border-bottom: 1px dotted $link-color;
	}

	@include mq($min-width: $media-tablet) {
		margin-bottom: 100px;
	}
}

.pagination i {
	background-image: url(../images/calendario-icon.svg);
	background-size: contain;
	width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto 15px;
}

.resp-video {
	position: relative;
	/* 16:9 */
	//border: 1px solid red;
	height: 0;
	padding-bottom: 56.25%;
	&.article-youtube {
		margin-top: 30px;
	}
}

.resp-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 15px;
	width: 100%;
	height: 100%;

	@include media-breakpoint-up('lg') {
		border-radius: 0;
	}
}

.ubicacion-container {
	margin: 60px 0;

	@include media-breakpoint-up('lg') {
		margin: 70px 0;
	}
}

.video {
	margin-bottom: 60px;

	@include media-breakpoint-up('lg') {
		margin-bottom: 70px;
	}
}

.medioambiente-link {
	margin: 60px 0 100px;

	@include media-breakpoint-up('lg') {
		margin: 70px 0;
	}
}

#video-main {
	display: block;
	position: relative;
	max-width: 900px;
	margin: auto;

	.splash {
		width: 100%;
		max-width: 100%;
		height:
		auto;
	}
	.video-main-player {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 130px;
		height: 130px;
		z-index: 9;
	}
}

// UBICACION
.ubicacion {
	position: relative;
	border: 1px solid $headings-color;
	border-radius: 15px;
	max-width: 500px;
	margin: auto;
	margin-top: 20px;
	padding: 25px 25px 25px 90px;
	display: block;
	transition: all 0.2s ease-in;

	&:hover {
		background-color: rgba(121, 185, 212, 0.35);
	}

	.ubicacion-text {
		color: $headings-color;
		font-size: 16px;
		line-height: 20px;

		@include media-breakpoint-up('lg') {
			font-size: 18px;
			line-height: 22px;
			margin-left: 25px;
		}
	}
}

.ubicacion-icon {
	display: block;
	height: 90px;
    position: absolute;
    top: -29px;
    left: 8px;
}

//SITIOS CERCANOS
.destino-img {
	object-fit: cover;
	height: 160px;
	border-radius: 15px;

	@include media-breakpoint-up('lg') {
		height: 200px;
	}
}

.destino-info {
	margin-top: 20px;

	.destino-icon {
		width: 25px;
		position: absolute;
		margin-right: 15px;
	}

	.destino-texto {
		color: #000;
		padding-left: 35px;

		span {
			display: block;
			font-size: 14px;
			line-height: 20px;

			@include media-breakpoint-up('lg') {
				font-size: 16px;
				line-height: 22px;
			}
		}

		p {
			color: #bcc0cc;
			font-size: 14px;

			@include media-breakpoint-up('lg') {
				font-size: 16px;
			}
		}
	}
}

//MEDIOAMBIENTE
.medioambiente-container {
	background-color: #196332;
	//margin-top: 70px;

	.body-main {
		background-color: #196332;
	}

	h3,
	p,
	.ubicacion-text {
		color: #fff;
	}

	.ubicacion-text {

		margin: 0;

	}

	.ubicacion {
		border-color: #fff;

		&:hover {
			background-color: rgba(255,255,255,0.2);
		}
	}

	.ubicacion-icon {
		height: 90px;
		left: 4px;
	}
}

.medioambiente-image {

	background-repeat: no-repeat;
	background-size: cover;
	min-height: 400px;
	position: relative;

	@include media-breakpoint-up('lg') {

		min-height: 700px;

	}

}
