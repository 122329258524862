@mixin font-size-media($mobile,$tablet,$desktop,$desktopxl) {

    $font-list: $tablet $desktop $desktopxl;
    font-size: $mobile;

    @each $mq in $media {

        $i: index($media, $mq);

        @include mq($min-width: $mq) {

           font-size: nth($font-list, $i);
           
        }
          
    }

}

@mixin generic-icon($width, $height, $pos-x, $pos-y, $top, $position, $position-value, $padding) {
    
    position: relative;
    padding-#{$position}: $padding;

    &:after {
        content: "";
        width: $width;
        height: $height;
        position: absolute;
        top: $top;
        #{$position}: $position-value;
        display: block;
        background-image: url('/user/themes/calendario/images/fundacion-sprite.svg');
        background-repeat: no-repeat;
        background-position: $pos-x $pos-y;
    }
}